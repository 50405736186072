import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <article className="bg-washed-blue flex flex-column vh-75 justify-center">
      <header className="tc lh-copy">
        <h1 className="f1 tc small-caps fw8 tracked-tight">HTTP Error 404</h1>
        <h2 className="i f2 fw1">
          Sorry, we can&apos;t find the page you are looking for.
        </h2>
      </header>
      <section className="lh-copy measure-wide center">
        <p>
          If you reached this page erroneously through one of the above pages,
          please contact the{" "}
          <a
            className="link hover-light-blue bg-purple white"
            href="mailto:webmaster@gabriellemoncrease.com"
          >
            Webmaster
          </a>
          .
        </p>
        <p className="lh-copy measure-wide center">
          Otherwise, please use one of the above links, or go to the{" "}
          <a className="link hover-light-blue bg-purple white" href="/">
            home page
          </a>{" "}
          and try again
        </p>
      </section>
    </article>
  </Layout>
)

export default NotFoundPage
